.titulo{
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align:center;
    margin: auto;
    padding: auto;
    border-color: #20ddb4;
    border-style:solid;
    border-radius: 50px;
    border-width: 1px;
    width: 400px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.pesquisa{
    text-align:center;
    margin: auto;
    padding: 5px;
    width: 90%;
    margin-top: 10px;
    padding-bottom: 0px;
}
.table{
    text-align:center;
    margin: auto;
    padding: 5px;
    width: 90%;
    margin-top: 10px;
    padding-bottom: 0px;
    height: 735px;
}